import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, ContentNode } from '@shapeable/ui';
import { IndicatorValue } from '@shapeable/lli-types';
import { IndicatorValueBar } from './indicator-value-bar';
import { COLOR_INDICATOR_VALUE_BG } from '../../theme';
import { IndicatorValueBoolean } from './indicator-value-boolean';
import { IndicatorValueNumber } from './indicator-value-number';
import { classNames } from '@shapeable/utils';
const cls = classNames('indicator-value-layout');

export type IndicatorValueVariant = 'single' | 'double';

// -------- Types -------->

export type IndicatorValueGroup = { groupName: string; indicatorValues: IndicatorValue[] };

export type IndicatorValueItem = IndicatorValue | IndicatorValueGroup;


export const isSingleIndicatorValue =
  (item: IndicatorValueItem): item is IndicatorValue => !item.hasOwnProperty('indicatorValues');


export type IndicatorValueLayoutProps = Classable & HasChildren & { 
  entity?: IndicatorValueItem;
  backgroundColor?: string;
  color?: string;
  title?: string;
  variant?: IndicatorValueVariant;
};

export const IndicatorValueLayoutDefaultProps: Omit<IndicatorValueLayoutProps, 'entity'> = {
  backgroundColor: COLOR_INDICATOR_VALUE_BG,
  variant: 'single',
  color: 'light',
};

// -------- Child Component Props -------->

type ContainerProps = {
  _color: string;
  _backgroundColor: string;
  _blocks: number;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    font-size: ${theme.FONT_SIZE(14)};
    font-family: "IBM Plex Sans", sans-serif;
    padding: ${theme.UNIT(4)} ${theme.UNIT(4)} ${theme.UNIT(8)};
    ${({ _backgroundColor, _color }: ContainerProps ) => css`
      color: ${theme.COLOR(_color)};
      background-color: ${theme.COLOR(_backgroundColor)};
      width: 100%;
      a {
        color: inherit;
        text-decoration: underline;
      }
    `}
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(15)};
    padding: ${theme.UNIT(4)} ${theme.UNIT(5)} ${theme.UNIT(8)};
  `,
  desktop: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(6)} ${theme.UNIT(8)};
    font-size: ${theme.FONT_SIZE(16)};
    ${({ _backgroundColor, _color, _blocks }: ContainerProps ) => css`
      width: calc(${_blocks * 33.333}% - 1px);
      color: ${theme.COLOR(_color)};
      background-color: ${theme.COLOR(_backgroundColor)};

      a {
        color: inherit;
        text-decoration: underline;
      }
    `}
  `
});

const TitleStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)};
  `,
});

const BodyStyles = breakpoints({
  base: css`
    width: 100%;
    display: flex;
    flex-direction: row;

  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const ValueBarStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
    &:last-child {
      margin-bottom: 0;
    }
  `,
});

const ValuesStyles = breakpoints({
  base: css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
});

const ValueBooleanStyles = breakpoints({
  base: css`
    
  `,
});


const ValueNumberStyles = breakpoints({
  base: css`
    
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Title: styled(CellLabel)`${TitleStyles}`,
    Body: styled.div`${BodyStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
      ValueBoolean: styled(IndicatorValueBoolean)`${ValueBooleanStyles}`,
      ValueNumber: styled(IndicatorValueNumber)`${ValueNumberStyles}`,
      Values: styled.div`${ValuesStyles}`,
      ValueBar: styled(IndicatorValueBar)`${ValueBarStyles}`,

};

export const IndicatorValueLayout: Shapeable.FC<IndicatorValueLayoutProps> = (props) => {
  const { className, children, backgroundColor, color, variant, entity } = props;

  const isSingleIndicator = isSingleIndicatorValue(entity);

  const values = isSingleIndicator ? [entity] : entity.indicatorValues;
  const title = isSingleIndicator ? (props.title || entity.indicator?.title || entity.indicator?.name) : (props.title || entity.groupName);
  
  return (
    <My.Container _blocks={variant === 'single' ? 1 : 2} _color={color} _backgroundColor={backgroundColor} className={cls.name(className)}>
      <My.Title>{title}</My.Title>
      <My.Body>
      {
        isSingleIndicator && <>
        {
          entity.indicator?.unit?.slug === 'yes-or-no' && 
          <My.ValueBoolean entity={entity} isCrisisLineProvision={entity.indicator?.slug === "crisis-line-provision"}/> 
        }
        {
          entity.indicator?.unit?.valueType?.slug === 'number' &&
          <My.ValueNumber isFeature={entity.indicator?.slug === 'population-in-need-of-support-per-year'} hasSdg={entity.indicator.slug === 'suicide-rate'} entity={entity} />
        }
        </>
      }

      {
        !isSingleIndicator && <My.Values>
        {
        values.map((value, index) => {
          const { indicator, numericValue } = value;
          const { unit } = indicator;
          const { valueType } = unit;

          return (
            unit?.slug === '1-to-5' &&
            <My.ValueBar key={`${indicator.id}-${index}`} entity={value} />
          )
        })
        }
        </My.Values>
      }
      </My.Body>
    </My.Container>
  )
};

IndicatorValueLayout.defaultProps = IndicatorValueLayoutDefaultProps;
IndicatorValueLayout.cls = cls;