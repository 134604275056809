import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Icon, useActiveEntity, useLink } from '@shapeable/ui';
import { ArrowLeftIconGlyph } from '@shapeable/icons';
import { classNames } from '@shapeable/utils';
const cls = classNames('back-button');

// -------- Types -------->

export type BackButtonProps = Classable & HasChildren & {
  href?: string;
}

export const BackButtonDefaultProps: BackButtonProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    a {
      color: ${theme.COLOR('secondary')};
      text-decoration: none;
    }
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    gap: ${theme.UNIT(4)};
    &:hover .shp--icon {
      transform: translateX(-6px);
      transition: transform 0.2s ease; 
    }
  `,
});

const IconStyles = breakpoints({
  base: css`
    transform: translateX(0);
    transition: transform 0.2s ease;
  `,
});

const LabelStyles = breakpoints({
  base: css`
    font-weight: 500;
    letter-spacing: normal;
    font-size: ${theme.FONT_SIZE(13)};
  `,
});

const ArrowStyles = breakpoints({
  base: css`
   
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      Icon: styled(Icon)`${IconStyles}`,
      Label: styled.span`${LabelStyles}`,
      Arrow: styled(ArrowLeftIconGlyph)`${ArrowStyles}`,
};

export const BackButton: Shapeable.FC<BackButtonProps> = (props) => {
  const { className, href } = props;

  const { Link } = useLink();

  // const entity = useActiveEntity();

  return (
    <My.Container className={cls.name(className)}>
      <Link href={href}>
      <My.Body>
        <My.Icon size={16}><My.Arrow /></My.Icon>
        <My.Label>Back to Country List</My.Label>
      </My.Body>
      </Link>
    </My.Container>
  )
};

BackButton.cls = cls;
BackButton.defaultProps = BackButtonDefaultProps;