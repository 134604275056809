import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, ContentEntity, Dictionary, HasChildren, Shapeable } from '@shapeable/types';
import { Economy } from '@shapeable/lli-types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, ContentEntityThumbnailGrid, ContentNode, ContentTitle, DottedLine, EntityMultiValueList, MainAside, SliceLayoutBoundary, useEntity, useFeedEntries, useLang, useLink, usePosts } from '@shapeable/ui';
import { ECONOMY_ADVOCACY_TITLE, ECONOMY_CHANGE_TITLE, ECONOMY_CHANGING_LAW_TITLE, ECONOMY_CONCLUSION_TITLE, ECONOMY_CONTEXT_TITLE, ECONOMY_LEGAL_TITLE, ECONOMY_PROVISIONS_TITLE, ECONOMY_LEGISLATIVE_TITLE, DISCLAIMER } from '../../data';
import { EntityContentReveal } from './entity-content-reveal';
import { IndicatorValueGroup, IndicatorValueItem, IndicatorValueLayout, IndicatorValueVariant, isSingleIndicatorValue } from './indicator-value-layout';
import { sortBy, reverse, groupBy, map, includes } from 'lodash';
import { IsLliMemberProvider } from '../context/is-member-context';

import { classNames, entityLabel } from '@shapeable/utils';
const cls = classNames('country-overview-layout');

// -------- Types -------->

export type CountryOverviewLayoutProps = Classable & HasChildren & { 
  entity?: Economy;
};

export const CountryOverviewLayoutDefaultProps: Omit<CountryOverviewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-weight: 400;
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const MainStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(6)};
  `,
  tablet: css`
  `,
});

const BodyStyles = breakpoints({
  base: css`
    
  `,
});

const DisclaimerStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(12)};
    font-weight: 300;
    font-style: italic;
  `,
});



const IntroductionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(12)};
    font-size: ${18/16}em;
    font-weight: 400;
    color: ${theme.COLOR('text')};
  `,
  tablet: css`
    font-size: ${18/16}em;
  `,
  desktop: css`
    font-size: ${20/16}em;
  `,

});


const ContentTitleStyles = breakpoints({
  base: css`
   padding-bottom: ${theme.UNIT(4)};
   font-size: ${theme.FONT_SIZE(15)};
  `,
});

const AsideStyles = breakpoints({
    base: css`
      
    `,
});

const AsideTitleStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
    color: ${theme.COLOR('secondary')};
    text-transform: uppercase;
  `,
});


const ContentStyles = breakpoints({
  base: css`
    
   
  `,
});

const ListStyles = breakpoints({
  base: css`
    .shp--cell-header {
      display: none;
    }
    a {
      color: ${theme.COLOR('dark')};
    }
  `,
});

const FooterStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(4)};
    margin-top: ${theme.UNIT(8)};
  `,
});

const PostsStyles = breakpoints({
  base: css`
    
  `,
});

const IndicatorValueStyles = breakpoints({
  base: css`
    
  `,
});

const IndicatorsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1px;
    margin-right: -1px;
    margin-bottom: ${theme.UNIT(10)};
  `,
});

const DottedLineStyles = breakpoints({
  base: css`
    
  `,
});

const TitleLinkStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('link-hover')};
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(13)};
    font-family: ${theme.FONT('sans')};
    font-style: italic;
    text-decoration: none;
    &:hover {
      filter: brightness(1.1);
    }
  `,
});

const AsideItemStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Content: styled(EntityContentReveal)`${ContentStyles}`,

    Layout: styled.div`${LayoutStyles}`,
      Main: styled.div`${MainStyles}`,
        Body: styled.div`${BodyStyles}`,
          Indicators: styled.div`${IndicatorsStyles}`,
            IndicatorValue: styled(IndicatorValueLayout)`${IndicatorValueStyles}`,
         
          Disclaimer: styled(ContentNode)`${DisclaimerStyles}`,
          Introduction: styled(ContentNode)`${IntroductionStyles}`,
          ContentTitle: styled(ContentTitle)`${ContentTitleStyles}`,
          DottedLine: styled(DottedLine)`${DottedLineStyles}`,
          

        Footer: styled.div`${FooterStyles}`,
          Posts: styled(ContentEntityThumbnailGrid).attrs(cls.attr('posts'))`${PostsStyles}`,


      Aside: styled.div`${AsideStyles}`,
        AsideItem: styled.div`${AsideItemStyles}`,
          AsideTitle: styled(CellLabel)`${AsideTitleStyles}`,
          List: styled(EntityMultiValueList)`${ListStyles}`,
      
      TitleLink: styled.a`${TitleLinkStyles}`

      
};

export const CountryOverviewLayout: Shapeable.FC<CountryOverviewLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const t = useLang();

  const { Link } = useLink();
  const languagesWikiUrl = "https://en.wikipedia.org/wiki/List_of_official_languages_by_country_and_territory";

  const { 
    intro, isLliMember, theLegalSystem, theEffectsOfChange, provisionsActsAndPunishments, 
    culturalSocialAndReligiousContext, advocacyAndStakeholders, howToChangeTheLaw, 
    conclusion, methodologies = [], legislativeReformAndDevelopments, legalSystems = [], 
    spokenLanguages = [], organisationRoles = [], indicatorValues = [], economyMethodologies = [],
    feedEntries = [], posts = [] 
  } = entity;

  const hasMethodologies = !!methodologies.length;
  const hasLegalSystems = !!legalSystems.length;
  const hasSpokenLanguages = !!spokenLanguages.length;
  const hasOrganisationRoles = !!organisationRoles.length;

  const allLliFeedEntries = useFeedEntries();
  const allLliPosts = usePosts();

  const hasFeaturedPosts = !!feedEntries.length || !!posts.length;

  const publications = hasFeaturedPosts ? reverse(sortBy([...feedEntries, ...posts], 'published')) as ContentEntity[] : reverse(sortBy([...allLliFeedEntries, ...allLliPosts], 'published')) as ContentEntity[];

  const hasPublications = !!publications.length;
  const indicatorValueItems: IndicatorValueItem[] = [];

  // these need to be processed in order so groups can be inserted on the fly and still in order

  for (const indicatorValue of indicatorValues) {
    const groupName = indicatorValue?.indicator?.group?.name || '';
    
    if (!groupName) {
      indicatorValueItems.push(indicatorValue);
    } else {
      const existingGroup: IndicatorValueGroup = indicatorValueItems.find((item) => !isSingleIndicatorValue(item) && item.groupName === groupName) as IndicatorValueGroup;

      if (existingGroup) {
        existingGroup.indicatorValues.push(indicatorValue);
      } else {
        // start a new group
        indicatorValueItems.push({
          groupName: groupName,
          indicatorValues: [indicatorValue]
        })
      }
    }
  }

  return (
    <IsLliMemberProvider value={isLliMember}>
    <My.Container className={cls.name(className)}>
      <My.Layout>
        {
          !!indicatorValues.length &&
          <My.Indicators>
            {
              indicatorValueItems.map((item, index) => {
                const variant: IndicatorValueVariant = isSingleIndicatorValue(item) && includes(['population-in-need-of-support-per-year'], item?.indicator?.slug) ? 'double' : 'single';
                return (
                  <My.IndicatorValue key={`indicator-value-${index}`} variant={variant} entity={item} />
                )
              })
            }
          </My.Indicators>
        }
        <SliceLayoutBoundary>
        <MainAside 
          main={
            <My.Main>
              {
                intro?.text && <>
                 <My.Introduction entity={intro} />
                </>
              }
              <My.Disclaimer entity={DISCLAIMER} />
              {
                theLegalSystem?.text &&
                <My.Content entity={theLegalSystem} title={t(ECONOMY_LEGAL_TITLE)} />
              }
              {
                provisionsActsAndPunishments?.text &&
                <My.Content entity={provisionsActsAndPunishments} title={t(ECONOMY_PROVISIONS_TITLE)} />
              }
              {
                culturalSocialAndReligiousContext?.text &&
                <My.Content entity={culturalSocialAndReligiousContext} title={t(ECONOMY_CONTEXT_TITLE)} />
              }
              {
                howToChangeTheLaw?.text &&
                <My.Content entity={howToChangeTheLaw} title={t(ECONOMY_CHANGING_LAW_TITLE)} />
              }
              {
                advocacyAndStakeholders?.text &&
                <My.Content entity={advocacyAndStakeholders} title={t(ECONOMY_ADVOCACY_TITLE)} />
              }
              {
                legislativeReformAndDevelopments?.text &&
                <My.Content entity={legislativeReformAndDevelopments} title={t(ECONOMY_LEGISLATIVE_TITLE)} />
              }
              {
                theEffectsOfChange?.text &&
                <My.Content entity={theEffectsOfChange} title={t(ECONOMY_CHANGE_TITLE)} />
              }
              {
                conclusion?.text &&
                <My.Content entity={conclusion} title={t(ECONOMY_CONCLUSION_TITLE)} />
              }
              <My.DottedLine height={2} gap={5}  />
            </My.Main>
        }
          aside={
            <My.Aside>
              {
                hasLegalSystems && 
                <My.AsideItem>
                  <My.AsideTitle>{t('Legal Systems:')}</My.AsideTitle>
                  <My.List variant='tiny' items={legalSystems} />
                </My.AsideItem>
              }
              {
                hasSpokenLanguages && 
                <My.AsideItem>
                  <My.AsideTitle>{t('Official Languages:')}</My.AsideTitle>
                  <My.List variant='tiny' items={spokenLanguages} />
                  <My.TitleLink href={languagesWikiUrl} target='_blank'>{t('Classification via Wikipedia')}</My.TitleLink>
                </My.AsideItem>
              }
              {/* {
                map(groupBy(organisationRoles, 'role.name'), (organisationRoles, role) => {
                  const organisations = organisationRoles.map(({ organisation }) => organisation);
                  return (
                  <My.AsideItem>
                    <My.AsideTitle>{t(pluralize.plural(role))}:</My.AsideTitle>
                    <My.List variant='tiny' items={organisations} />
                  </My.AsideItem>
                  )
                })

              } */}
              {
                hasMethodologies && 
                <My.AsideItem>
                  <My.AsideTitle>{t('Opportunities To Make Change:')}</My.AsideTitle>
                  <My.List variant='tiny' items={methodologies} />
                </My.AsideItem>
              }

            </My.Aside>
          }>
        </MainAside>
        
        <My.Footer>
          {
            hasPublications && 
            <>
              <My.AsideTitle>{t('Latest News and References:')}</My.AsideTitle>
              <My.Posts items={publications.slice(0, 4)} />
            </>
          }
        </My.Footer>
        </SliceLayoutBoundary>
        
      </My.Layout>
    {children}
    </My.Container>
    </IsLliMemberProvider>
  )
};

CountryOverviewLayout.defaultProps = CountryOverviewLayoutDefaultProps;
CountryOverviewLayout.cls = cls;