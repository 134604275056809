import { graphql } from "gatsby";
import { createGatsbyPageComponent } from "@shapeable/web";
import { CountryLayout } from "../../components/entities/country-layout";

export default createGatsbyPageComponent('Economy', { layout: CountryLayout });

export const QUERY = graphql`
  query EconomyQuery($id: ID!) {
    platform {
      economy(id: $id) {
        id name path slug __typename _schema { label pluralLabel }
        openGraph { title description { plain } image { url thumbnails { full { url } } } }
        banner { openGraph { id image { id url } } }
        description { plain text }
        isPriority
        isLliMember
        people {
          id name slug path __typename _schema { label pluralLabel }
          organisation { id name }
          position
          photo { id url type static { childImageSharp { gatsbyImageData } } }
        }
        videos {
          id name url __typename _schema { label pluralLabel }
          thumbnail { id url static { childImageSharp { gatsbyImageData } } }
        }
        posts {
          id name path openGraph { title description { plain } image { url thumbnails { full { url } } } }
          __typename _schema { label pluralLabel }
          published
          authors {
            name id slug path
          }
        }
        feedEntries {
          name id slug path url __typename _schema { label pluralLabel }
          openGraph { title description { plain } image { url thumbnails { full { url } } } }
          published author
        }
        intro { text }
        theLegalSystem { text }
        provisionsActsAndPunishments { text }
        culturalSocialAndReligiousContext { text }
        advocacyAndStakeholders { text }
        legislativeReformAndDevelopments { text }
        howToChangeTheLaw { text }
        theEffectsOfChange { text }
        conclusion { text }

        files {
          id name slug
          file { id url }
          thumbnail { 
            url
            static {
              childImageSharp { gatsbyImageData }
            }
          }
        }
        status { id name slug }
        legalSystems { id name slug __typename _schema { label pluralLabel } }
        methodologies { id name slug __typename _schema { label pluralLabel } }
        roles { 
          id name slug
          role { id name slug } 
          person { 
            id name slug path
            organisation { id name }
            position
            photo { id url type static { childImageSharp { gatsbyImageData } } }
          }
        }
        organisationRoles {
          id name slug __typename _schema { label pluralLabel }
          role { id name slug } 
          organisation {
            id name url __typename _schema { label pluralLabel }
            logoInverted { type url }
            logo { type url }
          }
        }
        
        indicatorValues {
          id value numericValue __typename _schema { label pluralLabel }
          indicator { 
            group { id slug name }
            description { text }
            id name title slug precision 
            unit { 
              id slug prefix suffix  __typename
              valueType { id slug } 
            } 
          }
        }
        spokenLanguages {
           id name iso slug __typename _schema { label pluralLabel }
        }
        members {
          id name slug path __typename _schema { label pluralLabel }
        }
        workstreams {
          id name path slug due __typename _schema { label pluralLabel }
          outlineNumber
          tasks {
            id path slug completed due name _label
            outlineNumber
            description { text }
          }
          status {
            id name slug
          }
          files {
            id name slug
          }
          people {
            id path slug name
            role { id name slug }
            organisation {
              id slug name
            }
            openGraph {
              id image {
                id url
              }
            }
            teamRoles {
              id slug path role {
                id name
              }
            }
          }
        }
      }
    }
  }
`