import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Economy, FeedEntry } from '@shapeable/lli-types';
import { breakpoints, theme } from '@shapeable/theme';
import { CitationCard, EntityCardGrid, EntityFilters, useEntity, useView } from '@shapeable/ui';
import { sortBy, reverse } from 'lodash';
import { classNames } from '@shapeable/utils';
const cls = classNames('country-publications-layout');

// -------- Types -------->

export type CountryPublicationsLayoutProps = Classable & HasChildren & { 
  entity?: Economy;
};

export const CountryPublicationsLayoutDefaultProps: Omit<CountryPublicationsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const FiltersStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
    Grid: styled(EntityCardGrid)`${GridStyles}`,
};

export const CountryPublicationsLayout: Shapeable.FC<CountryPublicationsLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { posts = [], feedEntries = [] } = entity;

  const hasPosts = !!posts.length
  const hasFeedEntries = !!feedEntries.length;
      

  const view = 'country-publications';
  const { resultsFor  } = useView(view);

  const FeedEntryWithName = feedEntries.map((page: FeedEntry) => {
    return {
      ...page,
      __typename: 'Citation',
    }
  });

  const publications = [
    ...(resultsFor({ type: 'Post', data: posts, by: 'name' })),
    ...(resultsFor({ type: 'FeedEntries', data: FeedEntryWithName, by: 'name' })),
  ];

  const sortedPublications = reverse(sortBy(publications, 'published'));

  return (
   <My.Container className={cls.name(className)}>
    <My.Filters 
    view={view}
    typeToggles={[
      { name: 'Post', label: 'Posts' },
      { name: 'FeedEntries', label: 'Articles' },
    ]}
    >

    </My.Filters>
    <My.Grid items={sortedPublications} spacing={2} />
   {children}
   </My.Container>
  )
};

CountryPublicationsLayout.defaultProps = CountryPublicationsLayoutDefaultProps;
CountryPublicationsLayout.cls = cls;